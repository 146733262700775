<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="新建物品" :visible.sync="addwarehouseDialogVisible" 
    :close-on-click-modal="false" width="30%" :close="closeDialog">
        <el-form ref="warehouseFormRef" :model="warehouseForm" :rules="warehouseFormRules" 
        label-width="70px" v-loading="loading">
            <el-form-item label="名称：" prop="Name">
                <el-input  placeholder="请输入名称" v-model="warehouseForm.Name"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="类型：">
                        <el-select class="select_div" placeholder="请选择物品类型" v-model="warehouseForm.TypeName" @change="typeHandleChange">
                            <el-option v-for="item in warehouseTypeList" :key="item.id" :label="item.name" :value="{value:item.id,label:item.name}">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="单位：">
                        <el-select class="select_div" placeholder="请选择物品计量单位" v-model="warehouseForm.Unit">
                            <el-option v-for="item in warehouseUnitList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="价值：" prop="PropertyValue">
                <el-input-number class="inputNumber_div" v-model="warehouseForm.PropertyValue" :precision="2" :step="0.01" :min="0" :max="9999"></el-input-number>
            </el-form-item>
             <el-form-item label="备注：" prop="Remark">
                <el-input  placeholder="请输入备注" v-model="warehouseForm.Remark"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog" :disabled="loading">取消</el-button>
            <el-button type="primary" @click="addWarehouse" :disabled="loading">确认</el-button>
        </span>
    </el-dialog>    
</template> 
<script>
import { GetWarehouseType, GetWarehouseUnit, PostWarehouse } from '@/assets/js/api/warehouseapi'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
    },
    computed:{
        addwarehouseDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            warehouseForm:{
                ID:0,
                Name:'',
                Type:0,
                TypeName:'',
                PropertyValue:0.0,
                Unit:'',
                Remark:''
            },
            warehouseFormRules:{
                Name: [
                    { required: true, message: '请输入物品名称', trigger: 'blur' }
                ],
            },
            warehouseTypeList:[],
            warehouseUnitList:[],
            loading:false,
            atLoading:true,
            auLoading:true,
        }
    },
    watch:{
        addwarehouseDialogVisible:function (newVisible) {
            if(newVisible){
                this.loading = true
                if(this.$refs.warehouseFormRef)
                {
                    this.$refs.warehouseFormRef.resetFields()
                }
                this.warehouseForm.TypeName = ''
                this.warehouseForm.Unit = ''
                this.getWarehouseUnitList()
                this.getWarehouseTypeList()
            }
        },
        atLoading:function(newVisible){
            if(!newVisible && this.auLoading == false){
                this.loading = false
            }
        },
        auLoading:function(newVisible){
            if(!newVisible && this.atLoading == false){
                this.loading = false
            }
        }
    },
    methods:{
        typeHandleChange(val){
            this.warehouseForm.Type = val.value
            this.warehouseForm.TypeName =  val.label
        },
        async getWarehouseUnitList(){
            this.auLoading = true
            var ret = await GetWarehouseUnit()
            this.auLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.warehouseUnitList = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async getWarehouseTypeList(){
            this.atLoading = true
            var ret = await GetWarehouseType()
            this.atLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.warehouseTypeList = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        closeDialog(){
            this.addwarehouseDialogVisible = false
        },
        async addWarehouse(){
            this.$refs.warehouseFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            const confirmResult = await this.$confirm('确认添加礼品 ' + this.warehouseForm.Name + ' ,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.loading = true
            var ret = await PostWarehouse(this.warehouseForm)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('添加物品成功');
                this.$emit("ChangeAddWarehouse", ret.data)
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.closeDialog()
        }
    }
}
</script>
<style lang="less" scoped>
    .dialog_div{
        text-align: center;
    }
    .inputNumber_div{
        width: 100%;
        display: flex;
    }
    .select_div{
        width: 100%;
        display: flex;
    }
</style>