<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入物品名称" v-model="queryInfo.Name" clearable @clear="queryInfoWarehouseList"> 
                        <el-button slot="append" icon="el-icon-search" @click="queryInfoWarehouseList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addWarehouseDialogVisible = true" :disabled="loading">新建物品</el-button>
                </el-col>
            </el-row>
            <el-table :data="warehouseList" v-loading="loading" @row-click="showWarehouseOperationDialog" height="74vh">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="类型" prop="typeName"></el-table-column>
                <el-table-column label="单个价值（￥）" prop="propertyValue"></el-table-column>
                <el-table-column label="库存" prop="count"></el-table-column>
                <el-table-column label="消耗" prop="sumOut"></el-table-column>
                <el-table-column label="单位" prop="unit"></el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
                <div slot="empty" class="empty_div">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="queryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="warehouseTotal">
            </el-pagination>
        </el-card>
        <AddWarehouseDialog :visible.sync="addWarehouseDialogVisible"
        v-on:ChangeAddWarehouse="queryInfoWarehouseList">
        </AddWarehouseDialog>

        <WarehouseOperationDialog :visible.sync="warehouseOperationDialogVisible" :warehouseinfodata="selectWarehouseData"
        v-on:ChangeWarehouse="queryInfoWarehouseList">
        </WarehouseOperationDialog>
    </div>
</template>

<script>
import { GetWarehouseQuery } from '@/assets/js/api/warehouseapi'
import AddWarehouseDialog from '../Dialog/addwarehousedialog.vue'
import WarehouseOperationDialog from '../Dialog/warehouseoperationdialog.vue'
import { SpreadCount } from '@/assets/js/systemvariable.js'
export default {
    components:{
        AddWarehouseDialog,
        WarehouseOperationDialog,
    },
    data(){
        return{
            queryInfo:{
                Name:'',
                Type:-1,
                Start:1,
                Count:SpreadCount[0],
            },
            spreadCount:SpreadCount,
            warehouseList:[],
            warehouseTotal:0,
            loading:false,
            addWarehouseDialogVisible:false,
            warehouseOperationDialogVisible:false,
            selectWarehouseData:{},
        }
    },
    created(){
        this.queryWarehouseList()
    },
    methods:{
        queryInfoWarehouseList(){
            this.queryInfo.Start = 1
            this.queryInfo.Count = this.spreadCount[0]
            this.queryWarehouseList()
        },
        async queryWarehouseList(){
            this.loading = true
            var ret = await GetWarehouseQuery(this.queryInfo)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.warehouseList = ret.data.warehouse
                this.warehouseTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        handleSizeChange(count){
            this.queryInfo.Count = count
            this.queryWarehouseList()
        },
        handleCurrentChange(start){
            this.queryInfo.Start = start
            this.queryWarehouseList()
        },
        showWarehouseOperationDialog(row){
            this.selectWarehouseData = row
            this.warehouseOperationDialogVisible = true
        }
    }
}
</script>