<template>
    <div>
        <el-descriptions title="物品信息" :column="5" size="medium" border v-loading="loading">
            <template slot="extra">
                <el-tooltip effect="dark" content="编辑物品信息" placement="top" :enterable="false">
                    <el-button type="primary" icon="el-icon-edit" @click="showModify"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="删除物品信息" placement="top" :enterable="false">
                    <el-button type="danger" icon="el-icon-delete" @click="deleteWarehouse"></el-button>
                </el-tooltip>
            </template>
            <el-descriptions-item label="名称">
                {{warehouseInfoData.name}}
            </el-descriptions-item>
            <el-descriptions-item label="单个价值（￥）">
                {{warehouseInfoData.propertyValue}}
            </el-descriptions-item>
            <el-descriptions-item label="库存">
                {{warehouseInfoData.count}}
            </el-descriptions-item>
            <el-descriptions-item label="消耗">
                {{warehouseInfoData.sumOut}}
            </el-descriptions-item>
            <el-descriptions-item label="单位">
                {{warehouseInfoData.unit}}
            </el-descriptions-item>
            <el-descriptions-item label="备注">
                {{warehouseInfoData.remark}}
            </el-descriptions-item>
        </el-descriptions>
        <ModifyWarehouseDialog :visible.sync="modifyWarehouseDialogVisible" :warehouseinfodata="warehouseInfoData"
        v-on:ChangeModifyWarehouse="ParentChangeModifyWarehouse($event)">
        </ModifyWarehouseDialog>
    </div>
</template>
<script>
import { DeleteWarehouse, GetWarehouseByID } from '@/assets/js/api/warehouseapi'
import ModifyWarehouseDialog from '../Dialog/modifywarehousedialog.vue'
export default {
    components:{
        ModifyWarehouseDialog,
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        warehouseinfodata:Object
    },
    computed:{
        warehouseInfoVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    created(){
        this.warehouseInfoData = this.warehouseinfodata
    },
    watch:{
        warehouseInfoVisible:function (newVisible) {
            if(newVisible){
                this.warehouseInfoData = this.warehouseinfodata
            }   
        }
    },
    data(){
        return{
            loading:false,
            warehouseInfoData:{},
            modifyWarehouseDialogVisible:false,
        }
    },
    methods:{
        ParentChangeModifyWarehouse(data){
            this.warehouseInfoData = data
            this.$emit("ChangeModifyWarehouse", data)
        },
        showModify(){
            this.modifyWarehouseDialogVisible = true
        },
        async requestWarehouse(){
            this.loading = true
            var ret = await GetWarehouseByID(this.warehouseInfoData.id)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.warehouseInfoData = ret.data
                return
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async deleteWarehouse(){
            const confirmResult = await this.$confirm('确认删除物品 ' + this.warehouseInfoData.name +' ，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.loading = true
            var ret = await DeleteWarehouse(this.warehouseInfoData.id)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除物品成功');
                this.$emit("ChangeDeleteWarehouse")
                return
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
    }
}
</script>
<style scoped lang="less">
  
</style>