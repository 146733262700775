<template>
    <div>
        <!-- 暂时先不做出库功能 2022-11-26 -->
        <el-row :gutter="30">
            <el-col :span="4">
                <el-button type="primary" :disabled="true" @click="showWarehouseOutbound">新建出库单</el-button>
            </el-col>
        </el-row>
        <el-table :data="warehouseOutboundList" stripe v-loading="loading" @row-click="showModifyDialog" height="calc(100vh - 60vh)">
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="出库去向">
                <el-table-column label="学生姓名" prop="studentName"></el-table-column>
                <el-table-column label="用户姓名" prop="userName"></el-table-column>
            </el-table-column>
            <el-table-column label="数量" prop="count"></el-table-column>
            <el-table-column label="出库人员" prop="operatorUser"></el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column label="操作" prop="option" width="80">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="deleteSelectData(scope.row)" :disabled="true"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            <div slot="empty" class="empty_div">
                <el-empty description="暂无数据！" />
            </div>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.Start"
            :page-sizes="spreadCount"
            :page-size="queryInfo.Count"
            layout="total, sizes, prev, pager, next, jumper"
            :total="warehouseOutboundTotal">
        </el-pagination>
    </div>
</template>

<script>
import { GetWarehouseOutboundQueryByWarehouseID } from '@/assets/js/api/warehouseapi'
import { SpreadCount } from '@/assets/js/systemvariable.js'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        warehouseinfodata:Object,
    },
    data(){
        return{
            queryInfo:{
                WarehouseID:0,
                Start:1,
                Count:SpreadCount[0],
            },
            spreadCount:SpreadCount,
            warehouseOutboundList:[],
            warehouseOutboundTotal:0,
            addWarehouseOutboundDialogVisible:false,
            modifyWarehouseOutboundDialogVisible:false,
            loading:false,
            selectData:{},
        }
    },
    computed:{
        warehouseOutboundVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    watch:{
        warehouseOutboundVisible:function (newVisible) {
            if(newVisible){
                this.queryInfo.WarehouseID = this.warehouseinfodata.id
                this.queryInfoWarehouseOutboundList()
            }
        }
    },
    created(){
        this.queryInfo.WarehouseID = this.warehouseinfodata.id
        this.queryInfoWarehouseOutboundList()
    },
    methods:{
        async deleteSelectData(){
            const confirmResult = await this.$confirm('确认删除出库单 ，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
        },
        parentChangeAddWarehouseReceipt(data){
            this.queryInfoWarehouseOutboundList()
            this.$emit("ChangeAddWarehouseReceipt", data)
        },
        parentChangeModifyWarehouseReceipt(data){
            this.queryInfoWarehouseOutboundList()
            this.$emit("ChangeModifyWarehouseReceipt", data)
        },
        showWarehouseOutbound(){
            this.addWarehouseReceiptDialogVisible = true
        },
        queryInfoWarehouseOutboundList(){
            this.queryInfo.Start = 1
            this.queryInfo.Count = this.spreadCount[0]
            this.queryWarehouseOutboundList()
        },
        async queryWarehouseOutboundList(){
            this.loading = true
            var ret = await GetWarehouseOutboundQueryByWarehouseID(this.queryInfo)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.warehouseOutboundList = ret.data.warehouseOutbounds
                this.warehouseOutboundTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        handleSizeChange(count){
            this.queryInfo.Count = count
            this.queryWarehouseOutboundList()
        },
        handleCurrentChange(start){
            this.queryInfo.Start = start
            this.queryWarehouseOutboundList()
        },
        showModifyDialog(data){
            this.selectData = data
            this.modifyWarehouseOutboundDialogVisible = true
        }
    }
}
</script>