import axios from 'axios';

//提交仓库物品信息
export async function PostWarehouse(Warehouse){
  var res = await axios.post('Warehouse/v1/Warehouse', Warehouse)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//查询仓库物品信息
export async function GetWarehouseQuery(queryinfo){
  var res = await axios.get('Warehouse/v1/WarehouseQuery', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//删除仓库物品信息
export async function DeleteWarehouse(ID){
    var res = await axios.delete('Warehouse/v1/Warehouse', {params: {id : ID}})
    .then(function(response){
        return response.data;
    })
    .catch(function (error){
        console.log(error);
        return null;
    });
    return res;
}

//获取仓库物品类型信息
export async function GetWarehouseType(){
    var res = await axios.get('Warehouse/v1/WarehouseType')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}

//获取仓库物品单位信息
export async function GetWarehouseUnit(){
    var res = await axios.get('Warehouse/v1/WarehouseUnit')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}

//修改仓库物品信息
export async function PutWarehouse(Warehouse){
  var res = await axios.put('Warehouse/v1/ModifyWarehouse', Warehouse)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//根据物品ID获取入库单列表
export async function GetWarehouseReceiptQueryByWarehouseID(queryinfo){
  var res = await axios.get('Warehouse/v1/WarehouseReceiptQueryByWarehouseID', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//提交仓库物品入库单
export async function PostWarehouseReceipt(WarehouseReceipt){
  var res = await axios.post('Warehouse/v1/WarehouseReceipt', WarehouseReceipt)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//删除仓库物品出库单
export async function DeleteWarehouseReceipt(ID){
  var res = await axios.delete('Warehouse/v1/WarehouseReceipt', {params: {id : ID}})
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//修改仓库物品入库单信息
export async function PutWarehouseReceipt(WarehouseReceipt){
  var res = await axios.put('Warehouse/v1/ModifyWarehouseReceipt', WarehouseReceipt)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//根据物品ID获取仓库里的物品信息
export async function GetWarehouseByID(id){
  var res = await axios.get('Warehouse/v1/WarehouseByID', {params: {id :id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//根据物品ID获取出库单列表
export async function GetWarehouseOutboundQueryByWarehouseID(queryinfo){
  var res = await axios.get('Warehouse/v1/WarehouseOutboundQueryByWarehouseID', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//获取所有可用礼品列表
export async function GetAllGiftList(){
  var res = await axios.get('Warehouse/v1/AllGift')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//根据PayID查询礼品列表
export async function GetGiftListByPayID(queryinfo){
  var res = await axios.get('Warehouse/v1/GiftByPay', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}