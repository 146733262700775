<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="新建入库单" :visible.sync="addWarehouseReceiptDialogVisible" 
    :close-on-click-modal="false" width="30%" :close="closeDialog">
        <el-form ref="warehouseReceiptFormRef" :model="warehouseReceiptForm" :rules="warehouseReceiptFormRules" 
        label-width="120px" v-loading="loading">
            <el-form-item label="供货商：" prop="Supplier">
                <el-input  placeholder="请输入供货商：" v-model="warehouseReceiptForm.Supplier"></el-input>
            </el-form-item>
            <el-form-item label="数量：" prop="Count">
                <el-input-number class="inputNumber_div" v-model="warehouseReceiptForm.Count" :precision="0" :step="1" :min="0" :max="9999"></el-input-number>
            </el-form-item>
            <el-form-item label="总价（￥）：" prop="TotalPrice">
                <el-input-number class="inputNumber_div" v-model="warehouseReceiptForm.TotalPrice" :precision="2" :step="0.01" :min="0" :max="9999"></el-input-number>
            </el-form-item>
            <el-form-item label="操作人：" prop="OperatorUser">
                <el-select class="select_div" placeholder="请选择操作人" v-model="warehouseReceiptForm.OperatorUser"  @change="userHandleChange">
                    <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="{value:item.id,label:item.name}">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog" :disabled="loading">取消</el-button>
            <el-button type="primary" @click="addWarehouseReceipt" :disabled="loading">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { GetAllUser } from '@/assets/js/api/userapi'
import { PostWarehouseReceipt } from '@/assets/js/api/warehouseapi'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        warehouseinfodata:Object,
    },
    computed:{
        addWarehouseReceiptDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        var checkCount = (rule,value,callback)=>{
            if(value > 0){
                return callback()
            }
            callback(new Error('数量不能小于等于0'))
        }
        return{
            warehouseReceiptForm:{
                ID:0,
                WarehouseID:0,
                Supplier:'',
                Count:0,
                TotalPrice:0.00,
                UnitPrice:0.00,
                OperatorID:0,
                OperatorUser:'',
            },
            warehouseReceiptFormRules:{
                Count: [
                    { required: true, message: '请输入数量', trigger: 'blur' },
                    { validator:checkCount, trigger: 'blur'}
                ],
                TotalPrice: [
                    { required: true, message: '请输入总价', trigger: 'blur' }
                ],
                OperatorUser:[
                    { required: true, message: '请选择操作人', trigger: 'change' }
                ]
            },
            loading:false,
            userList:[],
        }
    },
    watch:{
        addWarehouseReceiptDialogVisible:function (newVisible) {
            if(newVisible){
                if(this.$refs.warehouseReceiptFormRef)
                {
                    this.$refs.warehouseReceiptFormRef.resetFields()
                }
                this.warehouseReceiptForm.WarehouseID = this.warehouseinfodata.id
                this.getUser()
            }
        },
    },
    methods:{
        async getUser(){
            this.loading = true
            var ret = await GetAllUser()
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.userList = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        userHandleChange(val){
            this.warehouseReceiptForm.OperatorID = val.value
            this.warehouseReceiptForm.OperatorUser =  val.label
        },
        closeDialog(){
            this.addWarehouseReceiptDialogVisible = false
        },
        async addWarehouseReceipt(){
            this.$refs.warehouseReceiptFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            const confirmResult = await this.$confirm('确认新建入库单,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.loading = true
            var ret = await PostWarehouseReceipt(this.warehouseReceiptForm)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('添加入库单成功');
                this.$emit("ChangeAddWarehouseReceipt", ret.data)
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.closeDialog()
        }
    }
}
</script>

<style lang="less" scoped>
    .dialog_div{
        text-align: center;
    }
    .inputNumber_div{
        width: 100%;
        display: flex;
    }
    .select_div{
        width: 100%;
        display: flex;
    }
</style>