<template>
    <div>
        <el-row :gutter="30">
            <el-col :span="4">
                <el-button type="primary" :disabled="loading" @click="showWarehouseReceipt">新建入库单</el-button>
            </el-col>
        </el-row>
        <el-table :data="warehouseReceiptList" stripe v-loading="loading" @row-click="showModifyDialog" height="calc(100vh - 60vh)">
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="供应商" prop="supplier"></el-table-column>
            <el-table-column label="数量" prop="count"></el-table-column>
            <el-table-column label="总价（￥）" prop="totalPrice"></el-table-column>
            <!-- <el-table-column label="单价（￥）" prop="unitPrice"></el-table-column> -->
            <el-table-column label="入库人员" prop="operatorUser"></el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column label="操作" prop="option" width="80">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="deleteSelectData(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            <div slot="empty" class="empty_div">
                <el-empty description="暂无数据！" />
            </div>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.Start"
            :page-sizes="spreadCount"
            :page-size="queryInfo.Count"
            layout="total, sizes, prev, pager, next, jumper"
            :total="warehouseReceiptTotal">
        </el-pagination>
        <AddWarehouseReceiptDialog :visible.sync="addWarehouseReceiptDialogVisible" :warehouseinfodata="warehouseinfodata"
        v-on:ChangeAddWarehouseReceipt="parentChangeAddWarehouseReceipt($event)">
        </AddWarehouseReceiptDialog>
        <ModifyWarehouseReceiptDialog :visible.sync="modifyWarehouseReceiptDialogVisible" :warehousereceiptinfodata="selectData"
        v-on:ChangeModifyWarehouseReceipt="parentChangeModifyWarehouseReceipt($event)">
        </ModifyWarehouseReceiptDialog>
    </div>
</template>

<script>
import { GetWarehouseReceiptQueryByWarehouseID, DeleteWarehouseReceipt } from '@/assets/js/api/warehouseapi'
import AddWarehouseReceiptDialog from '../Dialog/addwarehousereceiptdialog.vue'
import ModifyWarehouseReceiptDialog from '../Dialog/modifywarehousereceiptdialog.vue'
import { SpreadCount } from '@/assets/js/systemvariable.js'
export default {
    components:{
        AddWarehouseReceiptDialog,
        ModifyWarehouseReceiptDialog,
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        warehouseinfodata:Object,
    },
    data(){
        return{
            spreadCount:SpreadCount,
            queryInfo:{
                WarehouseID:0,
                Start:1,
                Count:SpreadCount[0],
            },
            warehouseReceiptList:[],
            warehouseReceiptTotal:0,
            addWarehouseReceiptDialogVisible:false,
            modifyWarehouseReceiptDialogVisible:false,
            loading:false,
            selectData:{},
        }
    },
    computed:{
        warehouseReceiptVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    watch:{
        warehouseReceiptVisible:function (newVisible) {
            if(newVisible){
                this.queryInfo.WarehouseID = this.warehouseinfodata.id
                this.queryInfoWarehouseList()
            }
        }
    },
    created(){
        this.queryInfo.WarehouseID = this.warehouseinfodata.id
        this.queryInfoWarehouseList()
    },
    methods:{
        async deleteSelectData(data){
            const confirmResult = await this.$confirm('确认删除入库单 ，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.loading = true
            var ret = await DeleteWarehouseReceipt(data.id)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除入库单成功');
                this.queryInfoWarehouseList()
                this.$emit("ChangeDeleteWarehouseReceipt", data)
                return
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        parentChangeAddWarehouseReceipt(data){
            this.queryInfoWarehouseList()
            this.$emit("ChangeAddWarehouseReceipt", data)
        },
        parentChangeModifyWarehouseReceipt(data){
            this.queryInfoWarehouseList()
            this.$emit("ChangeModifyWarehouseReceipt", data)
        },
        showWarehouseReceipt(){
            this.addWarehouseReceiptDialogVisible = true
        },
        queryInfoWarehouseList(){
            this.queryInfo.Start = 1
            this.queryInfo.Count = this.spreadCount[0]
            this.queryWarehouseList()
        },
        async queryWarehouseList(){
            this.loading = true
            var ret = await GetWarehouseReceiptQueryByWarehouseID(this.queryInfo)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.warehouseReceiptList = ret.data.warehousereceipt
                this.warehouseReceiptTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        handleSizeChange(count){
            this.queryInfo.Count = count
            this.queryWarehouseList()
        },
        handleCurrentChange(start){
            this.queryInfo.Start = start
            this.queryWarehouseList()
        },
        showModifyDialog(data){
            this.selectData = data
            this.modifyWarehouseReceiptDialogVisible = true
        }
    }
}
</script>