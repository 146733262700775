<template>
    <el-dialog v-alterELDialogMarginTop="{marginTop:'10vh'}" title="物品详情" class="dialog_div" width="70%" 
    :visible.sync="warehouseInfoOperationDialogVisible" :append-to-body="true" 
    @close="closeDialog" :close-on-click-modal="false">
        <div>
            <el-row slot="header">
                <WarehouseInfoComponent ref="warehouseInfoComponentRef" :visible.sync="warehouseInfoOperationDialogVisible" :warehouseinfodata="warehouseinfodata"
                v-on:ChangeDeleteWarehouse="parentChangeDeleteWarehouse"
                v-on:ChangeModifyWarehouse="parentChangeModifyWarehouse">
                </WarehouseInfoComponent>
            </el-row>
            <div>
                <el-tabs @tab-click="tabsClick" tab-position="left" class="tab_div" v-model="activeName">
                    <el-tab-pane label="入库记录">
                        <WarehouseReceiptComponent :visible.sync="warehouseReceiptComponentVisible" :warehouseinfodata="warehouseinfodata"
                        v-on:ChangeAddWarehouseReceipt="parentChangeAddWarehouseReceipt($event)"
                        v-on:ChangeDeleteWarehouseReceipt="parentChangeDeleteWarehouseReceipt($event)"
                        v-on:ChangeModifyWarehouseReceipt="parentChangeModifyWarehouseReceipt($event)">
                        </WarehouseReceiptComponent>
                    </el-tab-pane>
                    <el-tab-pane label="出库记录">
                        <WarehouseOutboundComponent :visible.sync="warehouseOutboundComponentVisible" :warehouseinfodata="warehouseinfodata">
                        </WarehouseOutboundComponent>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import WarehouseInfoComponent from '../Component/warehouseinfo.vue'
import WarehouseReceiptComponent from '../Component/warehousereceipt.vue'
import WarehouseOutboundComponent from '../Component/warehouseoutbound.vue'
export default {
    components:{
        WarehouseInfoComponent,
        WarehouseReceiptComponent,
        WarehouseOutboundComponent,
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        warehouseinfodata:Object,
    },
    computed:{
        warehouseInfoOperationDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            activeName:'',
            warehouseReceiptComponentVisible:false,
            warehouseOutboundComponentVisible:false,
        }
    },
    watch:{
       warehouseInfoOperationDialogVisible:function (newVisible) {
            if(newVisible){
                this.activeName = '0'
                this.warehouseReceiptComponentVisible = true
            }
        }
    },
    methods:{
        tabsClick(tab){
            if(tab.label == '入库记录'){
                this.warehouseReceiptComponentVisible = true
                this.warehouseOutboundComponentVisible = false
            }
            else
            {
                this.warehouseReceiptComponentVisible = false
                this.warehouseOutboundComponentVisible = true
            }
        },
        closeDialog(){
            this.warehouseInfoOperationDialogVisible = false
            this.warehouseOutboundComponentVisible = false
            this.warehouseReceiptComponentVisible = false
        },
        parentChangeDeleteWarehouse(){
            this.$emit("ChangeWarehouse")
            this.closeDialog()
        },
        parentChangeModifyWarehouse(){
            this.$emit("ChangeWarehouse")
        },
        parentChangeAddWarehouseReceipt(data){
            this.$emit("ChangeWarehouse")
            this.warehouseinfodata.count += data.count
        },
        parentChangeDeleteWarehouseReceipt(data){
            this.$emit("ChangeWarehouse")
            this.warehouseinfodata.count -= data.count
        },
        parentChangeModifyWarehouseReceipt(){
            this.$refs.warehouseInfoComponentRef.requestWarehouse()
            this.$emit("ChangeWarehouse")
        }   
    }
}
</script>
<style lang="less" scoped>
    .dialog_div{
        text-align: center;
    }
    .tab_div{
        margin-top:20px ;
        height: 55vh;
        overflow: auto;
    }
</style>
